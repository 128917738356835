import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-61ea4542"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container",
  ref: "container",
  style: {
    "height": "93vh",
    "overflow-y": "auto"
  }
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  style: {
    "padding-top": "1vw",
    "padding-left": "2vw"
  }
};
const _hoisted_4 = {
  style: {
    "width": "96vw",
    "text-align": "left",
    "display": "flex",
    "flex-wrap": "wrap",
    "overflow-y": "scroll"
  }
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  style: {
    "color": "#B2B2B2",
    "text-align": "center",
    "width": "100%",
    "height": "auto",
    "margin": "0"
  }
};
const _hoisted_7 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_tab_bar = _resolveComponent("tab-bar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_van_swipe, {
    autoplay: 3000,
    height: _ctx.auto
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.banners, (image, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: index
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: image,
          style: {
            "width": "100%",
            "height": "auto"
          }
        }, null, 8, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["height"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.photoList, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: item.key,
      style: {
        "float": "left",
        "min-width": "47.5vw"
      },
      class: _normalizeClass({
        margin: index % 2 === 0
      })
    }, [_createElementVNode("img", {
      src: 'https://mbapicture.oss-cn-hangzhou.aliyuncs.com/' + item.catalogName + item.name,
      style: {
        "width": "47.5vw"
      },
      onClick: _cache[0] || (_cache[0] = $event => $options.showPopup($event))
    }, null, 8, _hoisted_5)], 2);
  }), 128))])]), _withDirectives(_createVNode(_component_van_loading, {
    size: "24px",
    style: {
      "text-align": "center"
    }
  }, {
    default: _withCtx(() => [_createTextVNode("加载中...")]),
    _: 1
  }, 512), [[_vShow, $data.loading]]), _withDirectives(_createElementVNode("p", _hoisted_6, "别拉了，真没了……", 512), [[_vShow, $data.finished]]), _createVNode(_component_van_popup, {
    show: $data.popShow,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => $data.popShow = $event),
    style: {
      "background": "none",
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $data.image,
      style: {
        "width": "100%",
        "height": "auto"
      }
    }, null, 8, _hoisted_7)]),
    _: 1
  }, 8, ["show"])], 512), _createVNode(_component_tab_bar, {
    active: 'photo'
  })], 64);
}
import "core-js/modules/es.array.push.js";
import TabBar from '../common/TabBar';

// onMounted(() =>{
//   alert(1)
//   this.loadData();
//   window.addEventListener('scroll',);
// })
// function findScroller(element) {
//     element.onscroll = function() { console.log(element)}

//     Array.from(element.children).forEach(findScroller);
// }

// findScroller(document.body);

function debounce(func, wait = 1000) {
  let timeout;
  return function (event) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.call(this, event);
    }, wait);
  };
}
export default {
  components: {
    TabBar
  },
  data() {
    return {
      checkedGoods: ['1', '2', '3'],
      photoList: [],
      catelogList: [],
      banners: ['https://mbapicture.oss-cn-hangzhou.aliyuncs.com/2023.12.17红毯走秀/2_2429129.jpg', 'https://mbapicture.oss-cn-hangzhou.aliyuncs.com/2023.12.17红毯走秀/2_2860335.jpg', 'https://mbapicture.oss-cn-hangzhou.aliyuncs.com/2023.12.17红毯走秀/微信图片_20231218200355.jpg'],
      loading: false,
      finished: false,
      popShow: false,
      page: 0,
      size: 20,
      image: ''
    };
  },
  mounted() {
    const container = this.$refs.container;
    container.addEventListener('scroll', this.handleScroll);
    this.loadData();
    // this.loading = false;
    // window.addEventListener('scroll',this.handleScroll,true);
  },
  computed: {},
  watch: {
    finished: {
      handler(newValue, oldValue) {
        if (newValue == false) {
          return;
        }
        this.loading = false;
        const container = this.$refs.container;
        console.log(container);
        container.removeEventListener('scroll', this.handleScroll);
      }
    }
  },
  methods: {
    showPopup(event) {
      var cc = event.target;
      this.popShow = true;
      this.image = cc.src;
    },
    // debounce(func, wait=1000){
    //   let timeout;
    //   return function(event){
    //     clearTimeout(timeout)
    //     timeout = setTimeout(()=>{
    //     func.call(this, event)
    //     },wait)
    //   }
    // },
    handleScroll: debounce(function (e) {
      const container = this.$refs.container;
      if (container.scrollTop + container.clientHeight + 200 >= container.scrollHeight) {
        if (this.loading === true) {
          return;
        }
        this.loadData();
      }
    }, 500),
    loadCatlog() {
      try {
        var paras;
        paras = {
          params: {}
        };
        this.$axios.get('/photo/catelogList/', paras).then(response => {
          this.catelogList = [...this.photoList, ...response.data];
        });
      } catch (error) {
        console.error(error);
      } finally {}
    },
    // 加载数据
    async loadData() {
      this.loading = true;
      const container = this.$refs.container;
      container.removeEventListener('scroll', this.handleScroll);
      try {
        var paras;
        paras = {
          params: {
            page: ++this.page,
            size: this.size
          }
        };
        this.$axios.get('/photo/pagelist/', paras).then(response => {
          var result = response.data;
          var pageList = result.data;
          this.photoList = [...this.photoList, ...pageList];
          console.log(result.page * result.size);
          if (result.page * result.size >= result.total) {
            this.finished = true;
          } else {
            container.addEventListener('scroll', this.handleScroll);
          }
        });
        // Notify({
        //     message: '数据加载成功',
        //     type: 'success'
        // });
      } catch (error) {
        container.addEventListener('scroll', this.handleScroll);
        console.error(error);
      } finally {
        this.loading = false;
      }

      // try {
      //   var last,paras;
      //   if(this.photoList.length>0){
      //     last=this.photoList[this.photoList.length-1];
      //     paras={ params: {bucketName:last.bucketName,prefix:last.prefix,marker:last.key,page:this.page++}};
      //   }
      //   else{
      //     paras={ params: {bucketName:'mbapicture',prefix:'非全中文2班破冰活动/',marker:'非全中文2班破冰活动/'}};
      //   }

      //   this.$axios.get('/photo/list/',paras).then(response => {
      //       this.photoList = [...this.photoList,...response.data];
      //   });
      //     // Notify({
      //     //     message: '数据加载成功',
      //     //     type: 'success'
      //     // });
      // }
      // catch (error) {
      //   console.error(error);
      // }
      // finally {
      //   this.loading = false;
      //   this.finished = true;
      // }
    },
    go(link) {
      this.$router.push(link);
    }
  }
};